<template>
  <AppInputWrapper v-slot="{ input }" :class="wrapperClass" ref="appInputWrapperRef">
    <PrimeDropdown
      ref="primeDropdownRef"
      v-model="input.model"
      class="input-input"
      v-bind="primeDropdownBindings"
      v-on="primeDropdownListeners"
    >
      <!-- selected option slot -->
      <template #value="{ value: selectedValue, placeholder, option }">
        <!--{{ value }} {{ option }}-->
        <div v-if="option" class="selected-value">
          <slot name="selected" :value="selectedValue" :option="option">
            <!-- Slot content if not specified -->
            <span> {{ option[selectedDisplayField || selectedValueField] }}</span>
          </slot>
        </div>
        <span v-else class="select-placeholder">
          {{ placeholder }}
        </span>
      </template>

      <!-- dropdown slot -->
      <template #option="{ option }">
        <AppRipple>
          <div
            class="dropdown-option"
            :analytics-name="
              analyticsName ? analyticsName + '-option-' + option[selectedValueField] : ''
            "
          >
            <slot name="option" :option="option">
              <span> {{ option[selectedDisplayField || selectedValueField] }}</span>
            </slot>
          </div>
          <hr v-if="option.separator" class="option-separator" />
        </AppRipple>
      </template>
    </PrimeDropdown>
  </AppInputWrapper>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { omit } from 'lodash-es'

import { useMediaQuery } from '@/composables/useMediaQuery'

import PrimeDropdown from '@/components/PrimeDropdown/PrimeDropdown'
import AppInputWrapper from '@/components/AppInputWrapper/AppInputWrapper'

export default {
  name: 'AppInputDropdown',
  emits: ['input', 'loaded', 'focus', 'show', 'hide'],
  components: {
    AppInputWrapper,
    PrimeDropdown,
  },
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedDisplayField: {
      type: String,
      default: '',
      required: true,
    },
    selectedValueField: {
      type: String,
      default: null,
      required: true,
    },
    dataKey: {
      type: String,
      default: 'index',
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    emptyFilterMessage: {
      type: String,
      default: 'No results found',
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup(props, { emit }) {
    let adjustedOptions = []
    let lastOptions = null
    const getOptions = () => {
      const options = props.options
      if (options !== lastOptions) {
        if (options && props.dataKey === 'index') {
          let i = 1
          adjustedOptions = options.map((option) => {
            if (!option.index) {
              option.index = option[props.selectedValueField] + '_' + i
            }
            i++
            return option
          })
        } else {
          adjustedOptions = options

          lastOptions = options
        }
      }
      return adjustedOptions
    }

    emit('loaded', this)

    // Ref for PrimeDropdown component
    const primeDropdownRef = ref(null)

    // Returns overlay visible from PrimeDropdown component ref
    const overlayVisible = computed(() => {
      const { value: primeDropdown } = primeDropdownRef
      if (primeDropdown) {
        return primeDropdown.overlayVisible
      }
      return false
    })

    const updateOverlayPanelMaxWidth = () => {
      const { overlay, container } = primeDropdownRef.value.$refs
      const windowWidth = Math.min(window.innerWidth, window.outerWidth) // Get smallest window size
      // Check if overlay width is greater than window width
      if (overlay.offsetWidth > windowWidth) {
        // Calculate the amount of screen space being used by the dropdown container
        // Add 2px for overlay negative margins
        const containerWidth = container.offsetWidth + container.getBoundingClientRect().x + 2
        // Calculate furthest right position overlay can be to remain inside viewport
        let right = containerWidth - windowWidth

        // Check if dropdown has parent element of app card
        // If so we need to do adjust the right incase the scrollbar is present
        const { parentAppCard } = primeDropdownRef.value
        if (parentAppCard) {
          const cardContent = parentAppCard.$el.querySelector('.card-content')
          if (cardContent.clientWidth < cardContent.scrollWidth) {
            right += cardContent.offsetWidth - cardContent.clientWidth
          }
        }

        primeDropdownRef.value.setOverlayPosition({ right })
      }
    }

    const updateItemsWrapperHeight = () => {
      if (mq.current == 'xs' && props.filter) {
        return
      }

      const getDropdownHeight = () => {
        const { itemsWrapper } = primeDropdownRef.value.$refs
        return itemsWrapper.getBoundingClientRect().top + itemsWrapper.offsetHeight
      }
      const { container } = primeDropdownRef.value.$refs

      const containerTop = container.getBoundingClientRect().top
      const containerBottom = containerTop + container.offsetHeight

      let viewportTop = 0 // Top of current viewport
      let viewportHeight = window.innerHeight // Total height of current viewport

      // Check if component is inside an app card
      // When component is inside of an app card update viewport top and height
      const { parentAppCard } = primeDropdownRef.value
      if (parentAppCard && mq.current === 'xs') {
        const appCardContent = parentAppCard.$el.querySelector('.card-content')
        if (appCardContent) {
          viewportTop = appCardContent.getBoundingClientRect().top
          viewportHeight = appCardContent.offsetHeight
        }
      }

      // Calculate distances from container and dropdown max height
      const distanceToTop = containerTop - viewportTop
      const distanceToBottom = viewportHeight - containerBottom
      const dropdownHeight = containerBottom + Math.min(400, getDropdownHeight())

      // Dropdown should display upwards if available viewport space above container
      // is greater than a available viewport space below container
      const isBottomUp = dropdownHeight > viewportHeight ? distanceToTop > distanceToBottom : false
      primeDropdownRef.value.isBottomUp = isBottomUp

      // Calculated new height for dropdown items
      const itemHeight = (isBottomUp ? distanceToTop : distanceToBottom) - 24 // 24px is tailwind mb-6/mt-6

      // Only override css max item height if calculated height is less that 400
      // 400px is css max height
      if (itemHeight < 400) {
        // Set a min item height of 2x container height (96px)
        const minItemHeight = (containerBottom - containerTop) * 2
        primeDropdownRef.value.itemsWrapperHeight = Math.max(minItemHeight, itemHeight) + 'px'
      }
    }

    // Used to keep track of dropdown visibility
    const isDropdownVisible = ref(false)
    const onShow = () => {
      isDropdownVisible.value = true

      if (primeDropdownRef.value) {
        updateItemsWrapperHeight()
        if (mq.current === 'xs' && !props.filter) {
          updateOverlayPanelMaxWidth()
        }
      }
      emit('show')
    }

    const onHide = () => {
      isDropdownVisible.value = false
      if (primeDropdownRef.value) {
        primeDropdownRef.value.isBottomUp = false
      }
      emit('hide')
    }

    const onFocus = (evt) => {
      if (primeDropdownRef.value && !isDropdownVisible.value && !overlayVisible.value) {
        primeDropdownRef.value.overlayVisible = true
      }
      emit('focus', evt)
    }

    const mq = useMediaQuery()

    // A computed array of classes to apply to AppInputWrapper
    const wrapperClass = computed(() => {
      return [
        {
          'input--no-border': !props.border,
          'input--no-arrow': !props.showArrow,
          'input--xs-filter-overlay': props.filter && overlayVisible.value && mq.current === 'xs',
          'input--no-label': appInputWrapperRef.value?.label.text.length === 0,
        },
      ]
    })

    // Ref for AppInputWrapper component
    const appInputWrapperRef = ref(null)

    // A computed object of bindings to apply to PrimeDropdown component
    const primeDropdownBindings = computed(() => {
      const { value: appInputWrapper } = appInputWrapperRef
      const {
        options, // Removes options prop as this is handled in getOptions function
        selectedDisplayField: optionLabel, // Maps selectedDisplayField prop to PrimDropdown optionLabel prop
        selectedValueField: optionValue, // Maps selectedValueField prop to PrimDropdown optionValue prop
        ...$props // All other props provided by this component
      } = props

      return {
        ...(appInputWrapper ? appInputWrapper.input.attrs : {}), // Adds all attributes from appInputWrapper
        ...$props,
        options: getOptions(),
        optionLabel,
        optionValue,
        ariaLabelledBy: appInputWrapper?.label.id, // Binds appInputWrapper label id to PrimeDropdown ariaLabelledBy prop
        screenSize: mq.current,
      }
    })

    // A computed object of listeners to apply to PrimeDropdown component
    const primeDropdownListeners = computed(() => {
      const { value: appInputWrapper } = appInputWrapperRef
      let appInputWrapperListeners = {} // Use an empty object as null cannot be spread

      if (appInputWrapper) {
        // Removes this components listener from AppInputWrapper,
        // when defined by consuming component.
        // If these listeners are not removed this component events will not be called.
        // These events are still emitted and can be used when using this component
        appInputWrapperListeners = omit(appInputWrapper.input.listeners, ['focus', 'show', 'hide'])
      }

      return {
        ...appInputWrapperListeners,
        focus: onFocus, // Adds onFocus event to PrimeDropdown component
        show: onShow, // Adds onShow event to PrimeDropdown component
        hide: onHide, // Adds onHide event to PrimeDropdown component
      }
    })

    return {
      appInputWrapperRef,
      wrapperClass,
      primeDropdownRef,
      primeDropdownBindings,
      primeDropdownListeners,
    }
  },
}
</script>

<style scoped>
::v-deep .md-ripple-wave {
  @apply bg-gray-lighter;
  &.md-ripple-enter {
    @apply opacity-100;
  }
}

.option-separator {
  @apply m-0;
  @apply text-gray-light;
}

.select-placeholder {
  @apply text-tertiary-text;
}

.input {
  &.input--no-border .input-input {
    @apply border-0;
    @apply shadow-none !important;
  }
  &.input--no-arrow ::v-deep .p-dropdown-trigger-icon {
    @apply hidden;
  }
}

/* Classes used to override mobile device styling of dropdown */
.input--xs-filter-overlay {
  @apply fixed top-0 left-0;
  @apply w-full h-full;
  @apply flex flex-col;
  @apply pt-4;
  @apply bg-white;
  @apply z-20;
  @apply mb-0;
  @apply overflow-hidden;

  ::v-deep .input-wrapper {
    @apply flex flex-col;
    @apply flex-grow;
  }

  ::v-deep .input-label {
    @apply text-center;
    @apply truncate;
    @apply mx-14;
  }

  ::v-deep .prime-dropdown {
    @apply flex flex-col flex-grow;
    @apply align-top;
    @apply p-0;
    @apply border-none shadow-none outline-none;

    .p-dropdown-filter-container {
      @apply p-4;

      input {
        @apply h-12 px-3;
        @apply border border-orange;
        @apply shadow-outline-input-focus;
        @apply rounded-lg;
        @apply type-subtitle;
      }
    }

    .p-dropdown-trigger {
      @apply fixed top-4 right-4;
      @apply text-secondary-text;

      .pi-chevron-down {
        @apply transform-none;
        @apply transition-none;
      }
    }
  }

  ::v-deep .p-dropdown-panel {
    @apply hidden;
    @apply relative;
    @apply flex flex-grow;
    @apply top-0 !important;
    @apply left-0 !important;
    @apply pl-0;

    /* Special case for card content when filter enabled */
    .card-content & {
      @apply left-0 !important;
      @apply pl-0;
    }

    @apply m-0;
    @apply overflow-y-auto;

    .p-dropdown-items-wrapper {
      @apply relative;
      @apply rounded-none;
      @apply flex-grow;
      @apply max-h-full;
    }
  }

  &.input--no-label {
    ::v-deep .prime-dropdown .p-dropdown-filter-container {
      @apply pt-11;
    }
  }
}
</style>
