<template>
  <AppInputWrapper v-slot="{ input, label }">
    <div class="flex">
      <div class="input-wrapper mr-4 w-3/5">
        <AppInputPhoneDropdown
          v-model="areaCodeInput"
          class="input-phone"
          :analytics-name="analyticsName ? analyticsName + '-areacode' : ''"
          :options="options"
          v-bind="input.attrs"
          placeholder=""
          :label="mq.current === 'xs' && isDropdownVisible ? label.text : ''"
          @show="isDropdownVisible = true"
          @hide="isDropdownVisible = false"
          @selectedOption="onSelectedPhoneOption"
        >
          <template v-if="selectedOptionWithFlag" #selectOptionWithFlag
            >&NonBreakingSpace;</template
          >
        </AppInputPhoneDropdown>
      </div>
      <div class="input-wrapper w-full">
        <AppInputText
          ref="userInputRef"
          v-model="userInput"
          class="input-phone"
          :analytics-name="analyticsName ? analyticsName + '-number' : ''"
          :disabled="input.attrs.disabled"
          :placeholder="input.attrs.placeholder"
          :aria-labelledby="input.attrs.labelId"
          :aria-invalid="input.attrs.ariaInvalid"
          :aria-describedby="input.attrs.ariaDescribedBy"
          @blur="$emit('inputOnBlur', $event)"
        />
      </div>
    </div>
  </AppInputWrapper>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

import { useFocusUserInputRef } from '@/composables/useInputHelpers'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppInputWrapper from '@/components/AppInputWrapper/AppInputWrapper'
import AppInputPhoneDropdown from '@/components/AppInputPhoneDropdown/AppInputPhoneDropdown'
import AppInputText from '@/components/AppInputText/AppInputText'

export default {
  name: 'AppInputPhone',
  components: {
    AppInputWrapper,
    AppInputPhoneDropdown,
    AppInputText,
  },
  emits: ['input', 'areaCodeInput', 'areaCodeInputOption'],
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: 'Phone',
      required: true,
    },
    selectedOptionWithFlag: {
      type: Boolean,
      default: false,
    },
    areaCode: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    validation: {
      type: Object,
      default: () => {},
    },
    helperText: {
      type: String,
      default: '',
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup($props, { emit }) {
    // Input References
    const userInput = ref('')
    const areaCodeInput = ref(null)
    const isDefaultAreaCode = ref(false)

    const areaCodeInputValue = computed(() => {
      const { value: areaCodeObj } = areaCodeInput
      return areaCodeObj ? areaCodeObj.value : ''
    })

    // When area code value changes, emit the areaCodeInput event
    watch(areaCodeInputValue, (value) => {
      emit('areaCodeInput', value)
    })

    // The value to send back to the controller
    const outputValue = computed(() => {
      return areaCodeInputValue.value + userInput.value
    })

    // When the value changes, emit the input event
    watch(outputValue, (v) => {
      if (isDefaultAreaCode.value) {
        emit('input', undefined)
        isDefaultAreaCode.value = false
      } else {
        emit('input', v)
      }
    })

    // When component has a value, set input values
    const updateInputValues = () => {
      let selectedOption

      if ($props.areaCode) {
        selectedOption = selectedOptionByAreaCode.value
      } else {
        selectedOption = $props.options.find((option) =>
          option.value.startsWith($props.value.substr(0, option.value.length))
        )
      }

      if (selectedOption) {
        areaCodeInput.value = selectedOption
        userInput.value = $props.value.substr(selectedOption.value.length)
      }

      if (areaCodeInput.value) {
        emit('areaCodeInputOption', areaCodeInput.value)
      }
    }

    const selectedOptionByAreaCode = computed(() =>
      $props.options.find((option) => option.nameAbbr === $props.areaCode.nameAbbr)
    )

    // Update input values if component has initial value
    if ($props.value.length === 0 && $props.areaCode) {
      isDefaultAreaCode.value = true
      areaCodeInput.value = $props.options.find(
        (option) => option.nameAbbr === $props.areaCode.nameAbbr
      )
    } else if ($props.value.length > 0) {
      updateInputValues()
    }

    // Update input values if props.value updated not from component
    watch(() => $props.value, updateInputValues)

    // Used to focus user input ref
    const { userInputRef, focus } = useFocusUserInputRef('input')

    const onSelectedPhoneOption = (value) => {
      focus()
      emit('areaCodeInputOption', value)
    }

    const mq = useMediaQuery()
    const isDropdownVisible = ref(false)

    return {
      userInput,
      areaCodeInput,
      userInputRef,
      focus,
      onSelectedPhoneOption,
      mq,
      isDropdownVisible,
    }
  },
}
</script>
<style lang="postcss" scoped>
.input-phone {
  @apply mb-0;
}
</style>
