<template>
  <AppCard class="card-stp">
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>{{ stepConfig.mainTitle }}</h3>
      </AppCardHeader>
    </template>

    <!-- mobile only -->
    <h3 class="type-h2 pb-4 sm:hidden">{{ stepConfig.mainTitle }}</h3>

    <form @submit.prevent="submit">
      <AppDynamicForm :fields="formFields" :disabled="isLoading" />

      <AppCardFooter>
        <AppButton type="submit" :disabled="isSubmitButtonDisabled" :loading="isLoading">
          Continue
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { computed, reactive, onBeforeMount, ref } from '@vue/composition-api'

import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'

import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'

import { getFieldValue } from '@/utils/form'

import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppButton from '@/components/AppButton/AppButton'

import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'RegisterPersonalDetailsSTP',
  components: {
    AppCard,
    AppCardHeader,
    AppCardFooter,
    AppDynamicForm,
    AppIcon,
    AppButton,
    IconChevronLeft,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    // Set default values
    const phoneDetails = ref({})
    const phonePrefix = ref('')
    // Get STP config
    const stepConfig = registrationStore.getRegistrationStepConfigById('contact')

    // Setup everything to render properly
    onBeforeMount(async () => {
      // Get mocked fields for this step
      corporateRegistrationStore.getMockedCustomerRequiredFields()
      // Tell the store which step we are on
      corporateRegistrationStore.setActiveStep(router.currentRoute.path)
      corporateRegistrationStore.activeStepIdx = stepConfig.stepId
      corporateRegistrationStore.setActiveStepPageTitle(stepConfig.stepTitle)
      // Setup fields
      let { fields } = corporateRegistrationStore.getMockedCustomerFields('contact')
      // Filtering by fields
      const fieldsToInclude = stepConfig?.fieldsToInclude
      fields = fields.filter(({ id }) => fieldsToInclude.includes(id))
      // Setup phone field
      fields = fields.map((field) => {
        if (field.id === 'phonenumber') {
          // Allow prefix with flags
          field['selectedOptionWithFlag'] = true
        }
        return field
      })
      // Render fields
      if (fields) {
        setupFields(fields, 'contact')
        // Delete leading & trailing spaces on input text after onblur event
        formFields.value.forEach((field, i) => {
          if (field.component.name === 'default' || field.component.name === '_default') {
            formFields.value[i]['listeners'] = {
              blur: (evt) => {
                if (field.value === evt.target.value) {
                  field.value = cleanTrailingSpace(field.value)
                }
              },
            }
          }
          // Update phone details
          if (field.name === 'phoneNumber') {
            // Set prefix
            const selectedPhoneDetails = corporateRegistrationStore.getSelectedPhoneDetails
            const getDefaultPhoneDetails = corporateRegistrationStore.getPhoneDetails
            formFields.value[i].props.areaCode =
              selectedPhoneDetails.number.length === 0
                ? getDefaultPhoneDetails
                : selectedPhoneDetails
            // event listeners
            formFields.value[i]['listeners'] = {
              // Get area code selection
              areaCodeInputOption: (value) => {
                phoneDetails.value = value
                formFields.value[i].props.areaCode = phoneDetails.value
              },
              areaCodeInput: (value) => {
                phonePrefix.value = value
              },
            }
          }
        })
      }
    })

    const activeStep = corporateRegistrationStore.activeStep
    const isSubmitButtonDisabled = computed(() => !hasFormFields.value || isFormInvalid.value)

    const goBack = () => {
      if (registrationStore.isStpAllowedCountry()) {
        authStore.sendToContinueAs()
      } else {
        registrationStore.redirectToOldFlow = true
        router.push({ name: 'RegisterBusinessInformation' })
      }
    }

    const submit = async () => {
      try {
        // Storing values to populate fields later
        await corporateRegistrationStore.submitFieldData({
          group: 'contact',
          formFields,
          send: false,
        })
        // Save fields for later
        corporateRegistrationStore.saveFormFields({
          group: 'contact',
          formFields,
          customFields: [
            {
              id: 'phoneprefix',
              value: `+${phonePrefix.value}`,
            },
            {
              id: 'phonenumber',
              value: getFieldValue('phonenumber', formFields).replace(phonePrefix.value, ''),
            },
          ],
        })
        // Set phone details
        const { id, country, value } = phoneDetails.value
        corporateRegistrationStore.phoneDetails = {
          number: phonePrefix.value,
          areaCode: value,
          nameAbbr: id,
          country: country,
        }
        analyticsStore.identify({
          userId: authStore.profileId,
          traits: {
            yearOfBirth: formFields.value.find((input) => input.id === 'dateofbirth')
              ? formFields.value.find((input) => input.id === 'dateofbirth').value
              : null,
            firstName: getFieldValue('firstname', formFields),
            lastName: getFieldValue('lastname', formFields),
            phoneCountry: phonePrefix.value,
            phoneNumber: getFieldValue('phonenumber', formFields).replace(phonePrefix.value, ''),
          },
        })

        analyticsStore.track({
          event: 'Personal Details Completed',
          traits: {
            referenceSource: 'XEMT Business',
            email: authStore.lastLogin,
          },
        })
        // Routing user to business information page
        router.push({ name: 'RegisterBusinessInformation' })
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submitting personal details',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    }

    return {
      mq: reactive(useMediaQuery()),
      activeStep,
      formFields,
      isLoading,
      isSubmitButtonDisabled,
      stepConfig,
      phoneDetails,
      goBack,
      submit,
      phonePrefix,
    }
  },
}
</script>
