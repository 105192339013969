<template>
  <div ref="container" :class="containerClass" class="prime-dropdown" @click="onClick($event)">
    <div class="p-hidden-accessible">
      <input
        :id="inputId"
        ref="focusInput"
        type="text"
        readonly
        :disabled="disabled"
        :tabindex="tabindex"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
        :aria-labelledby="ariaLabelledBy"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
      />
    </div>
    <input
      v-if="editable"
      type="text"
      class="p-dropdown-label p-inputtext"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="editableInputValue"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
      @focus="onFocus"
      @blur="onBlur"
      @input="onEditableInput"
    />
    <span v-if="!editable" :class="labelClass">
      <slot name="value" :value="value" :placeholder="placeholder" :option="selectedOption">
        {{ label }}
      </slot>
    </span>
    <div class="p-dropdown-filter-container" v-if="filter && overlayVisible">
      <input
        ref="filterInput"
        v-model="filterValue"
        type="text"
        autoComplete="off"
        class="p-dropdown-filter p-inputtext p-component"
        :placeholder="filterPlaceholder"
        @keydown="onFilterKeyDown"
        @input="onFilterChange"
        @focus="onFilterInputFocus"
        @blur="onFilterInputBlur"
      />
    </div>

    <div
      role="button"
      v-if="showClear && value != null"
      @click="onClearClick($event)"
      class="p-dropdown-trigger"
      tabindex="0"
    >
      <AppIcon class="pi pi-times" size="24" name="Clear">
        <IconClose></IconClose>
      </AppIcon>
    </div>

    <div
      class="p-dropdown-trigger"
      role="button"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
      :tabindex="overlayVisible ? 0 : -1"
      @keydown="onToggleKeydown"
    >
      <!--Ria Change Icon-->
      <AppIcon class="p-dropdown-trigger-icon pi pi-chevron-down" size="24" name="Toggle List">
        <IconClose v-if="screenSize === 'xs' && overlayVisible && filter"></IconClose>
        <IconChevronUp v-else></IconChevronUp>
      </AppIcon>
    </div>
    <Transition name="p-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
      <div
        v-if="overlayVisible"
        ref="overlay"
        class="p-dropdown-panel p-component"
        :class="{
          'p-dropdown-panel--bottom-up': isBottomUp,
        }"
        :style="{ right: overlayPos.right }"
      >
        <div
          ref="itemsWrapper"
          class="p-dropdown-items-wrapper"
          :style="{ 'max-height': itemsWrapperHeight }"
        >
          <ul class="p-dropdown-items" role="listbox">
            <li
              v-for="(option, i) of visibleOptions"
              :key="getOptionRenderKey(option)"
              v-ripple
              tabindex="0"
              @keydown="onOptionKeydown($event, option)"
              :class="[
                'p-dropdown-item',
                {
                  'p-highlight': isSelected(option),
                  'p-disabled': isOptionDisabled(option),
                },
              ]"
              :aria-label="getOptionLabel(option)"
              role="option"
              :aria-selected="isSelected(option)"
              @click="onOptionSelect($event, option)"
            >
              <slot name="option" :option="option" :index="i">
                {{ getOptionLabel(option) }}
              </slot>
            </li>
            <li
              v-if="
                filterValue && (!visibleOptions || (visibleOptions && visibleOptions.length === 0))
              "
              class="p-dropdown-empty-message"
            >
              {{ emptyFilterMessage }}
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconChevronUp, IconClose } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'PrimeDropdown',
  components: { AppIcon, IconChevronUp, IconClose },
  extends: Dropdown,
  props: {
    screenSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focused: false,
      filterValue: null,
      overlayVisible: false,
      itemsWrapperHeight: null,
      isBottomUp: false,
      overlayPos: {
        right: null,
      },
    }
  },
  computed: {
    isIosDevice() {
      const isiOS_1to12 = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      const isiOS13_iPad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
      return !window.MSStream && (isiOS_1to12 || isiOS13_iPad)
    },
    selectedOption() {
      return this.getSelectedOption()
    },
    labelClass() {
      return [
        'p-dropdown-label p-inputtext',
        {
          'p-placeholder': this.label === this.placeholder,
          'p-dropdown-label-empty':
            !this.$scopedSlots['value'] &&
            (this.label === 'p-emptylabel' || this.label.length === 0),
          hidden: this.overlayVisible && this.filter,
        },
      ]
    },
    filterValueEqualsSelectedOption() {
      const selectedOption = this.selectedOption
      if (selectedOption) {
        return this.filterValue === selectedOption[this.optionLabel]
      }
      return false
    },
    visibleOptions() {
      if (
        this.filterValue &&
        this.filterValue.trim().length > 0 &&
        !this.filterValueEqualsSelectedOption
      )
        return this.options.filter(
          (option) =>
            this.getOptionLabel(option)
              .toLocaleLowerCase(this.filterLocale)
              .indexOf(this.filterValue.trim().toLocaleLowerCase(this.filterLocale)) > -1
        )
      else return this.options
    },
    parentAppCard() {
      let parent = this.$parent
      while (parent && parent.$options._componentTag !== 'AppCard') {
        parent = parent.$parent
      }
      return parent
    },
  },
  watch: {
    overlayVisible(val) {
      if (!val) {
        this.isBottomUp = false
        this.itemsWrapperHeight = null
        this.setOverlayPosition(null)
      }
    },
  },
  methods: {
    onToggleKeydown(evt) {
      if (['Space', 'Enter'].includes(evt.code)) {
        evt.stopPropagation()
        evt.target?.blur()
        this.hide()
      }
    },
    onOptionKeydown($event, option) {
      if (['Enter', 'Space'].includes($event.code)) {
        $event.preventDefault()
        this.onOptionSelect($event, option)
      } else if ($event.code === 'ArrowUp') {
        this.onUpKey($event)
      } else if ($event.code === 'ArrowDown') {
        this.onDownKey($event)
      }
    },
    onOptionSelect(event, option) {
      let value = this.getOptionValue(option)
      this.updateModel(event, value)
      this.$refs.focusInput.focus()

      this.filterValue = this.getOptionLabel(option)
      this.$emit('selectedOption', option)

      setTimeout(() => {
        this.hide()
      }, 200)
    },
    onKeyDown(event) {
      switch (event.which) {
        //down
        case 40:
          this.onDownKey(event)
          break

        //up
        case 38:
          this.onUpKey(event)
          break

        //space
        case 32:
          if (!this.overlayVisible) {
            this.show()
            event.preventDefault()
          }
          break

        //enter and escape
        case 13:
        case 27:
          if (this.overlayVisible) {
            this.hide()
            event.preventDefault()
          }
          break

        default:
          this.search(event)
          break
      }
    },
    onFocus(event) {
      this.focused = true
      // IMPORTANT: only when relatedTarget exists otherwise it might be also fired when app gets backgrounded and moves to foreground again
      if (event.relatedTarget) {
        this.$emit('focus', event)
      }
    },
    onBlur(event) {
      this.focused = false
      this.$emit('blur', event)
    },
    bindResizeListener() {
      if (!this.resizeListener) {
        this.resizeListener = () => {
          if (this.overlayVisible && !(this.filter && this.screenSize === 'xs')) {
            this.hide()
          }
        }
        window.addEventListener('resize', this.resizeListener)
      }
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          // Added to stop app input wrapper label for trigger outside click,
          // when in mobile filter full screen mode
          if (this.overlayVisible && this.screenSize === 'xs' && this.filter) {
            return
          }

          if (
            this.overlayVisible &&
            this.$refs.overlay &&
            !this.$refs.container.contains(event.target) &&
            !this.$refs.overlay.contains(event.target)
          ) {
            this.hide()
          }
        }
        document.addEventListener('click', this.outsideClickListener)
      }
    },
    onFilterInputFocus() {
      // Set the itemsWrapper height to a fix calculate height on iOS devices
      // Used to stop overlay from being hidden behind soft keyboard
      // Window viewport is not resized on iOS, soft keyboard overlays window breaking flexbox
      if (this.isIosDevice && !this.itemsWrapperHeight) {
        const diff = screen.height - window.innerHeight
        this.itemsWrapperHeight = diff > 0 ? diff * 1.5 + 'px' : null
      }
      this.$emit('filterInputFocus')
    },
    onFilterInputBlur() {
      if (this.itemsWrapperHeight) {
        this.itemsWrapperHeight = null
      }
      this.$emit('filterInputBlur')
    },
    setOverlayPosition(position) {
      if (position) {
        this.overlayPos.right = `${position.right}px`
      } else {
        this.overlayPos.right = null
      }
    },
  },
}
</script>
<style scoped>
.prime-dropdown {
  @apply w-full;
  &:hover,
  &:focus,
  &:active {
    @apply outline-none;
  }

  &.p-disabled {
    @apply cursor-default;
    .p-dropdown-label,
    .p-dropdown-trigger {
      @apply cursor-default;
    }
  }

  &.p-inputwrapper {
    @apply p-3;
    @apply flex;
  }

  &.p-focus,
  &.p-inputwrapper-focus {
    @apply border-orange;
    @apply shadow-outline-input-focus;
  }

  .pi {
    @apply text-secondary-text;
  }

  .p-dropdown-label {
    @apply flex items-center;
    @apply p-0;

    &.hidden {
      @apply hidden;
    }
  }

  .p-dropdown-panel {
    @apply top-12 !important;
    @apply left-0 !important;
    @apply -mt-px;

    /* Dropdown alignment is 1px out */
    @apply -mr-px;
    @apply -ml-px;

    &.p-dropdown-panel--bottom-up {
      @apply bottom-12;
      top: unset !important; /* No tailwind equivalent */

      @screen sm {
        .p-dropdown-items-wrapper {
          @apply bottom-0;
        }
      }
    }

    .p-dropdown-items-wrapper {
      @apply border border-gray-light rounded-lg;
      @apply shadow-ria-1;
      @apply bg-white;
      @apply z-20;
      @apply rounded-lg;
      @apply min-w-full;

      max-height: 400px; /* No tailwind equivalent */

      /* Firefox scrollbar styling */
      scrollbar-color: rgba(0, 17, 51, 0.15) transparent;
      scrollbar-width: thin;

      @screen sm {
        @apply absolute;
        @apply max-w-lg;
      }

      @screen md {
        /* Firefox scrollbar styling */
        scrollbar-width: auto;

        /* Chrome, Safari, Edge styling */
        &::-webkit-scrollbar {
          width: 15px; /* No tailwind equivalent */
        }
      }
    }

    .p-dropdown-items {
      @apply rounded-lg;
      @apply py-2;
    }
    .p-dropdown-item {
      &:hover {
        @apply bg-gray-lighter;
      }

      &:focus,
      &:active,
      &.p-highlight {
        @apply bg-gray-lighter-active;
        @apply outline-none;
      }

      ::v-deep .dropdown-option {
        @apply px-6 py-3;
        @apply truncate;
      }
    }
  }

  .selected-value {
    @apply block;
    @apply truncate;
  }

  .p-dropdown-empty-message {
    @apply px-6 py-3;
    @apply truncate;
  }
}
.select-placeholder {
  @apply truncate;
}
.p-dropdown-trigger {
  .pi-chevron-down {
    transform: rotate(-180deg);
    transition: all 270ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &[aria-expanded='true'] {
    @apply text-orange-text;
    .pi-chevron-down {
      transform: rotate(0deg);
    }
  }
}

.p-dropdown-filter-container {
  @apply w-full;

  input {
    @apply outline-none;
    @apply w-full h-full;
    caret-color: theme('colors.orange.default');

    /*
      Force no box shadow when validation classes applied
      Important used here as the simpler option
    */
    &:focus {
      @apply shadow-none !important;
    }
  }
}
</style>
