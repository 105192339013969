<template>
  <Component :is="renderComponent" />
</template>

<script>
import { computed } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRegistrationStore } from '@/stores/registration'

import RegistrationPersonalDetailsSTP from './RegistrationPersonalDetailsSTP.vue'
import RegisterPersonal from './RegisterPersonal.vue'

export default {
  name: 'RegisterPersonalDetailsWrapper',
  components: {
    RegisterPersonal,
    RegistrationPersonalDetailsSTP,
  },
  setup() {
    const store = useStore()
    const registrationStore = useRegistrationStore()

    const renderComponent = computed(() => {
      return registrationStore.redirectToOldFlow
        ? 'RegisterPersonal'
        : 'RegistrationPersonalDetailsSTP'
    })

    return {
      renderComponent,
    }
  },
}
</script>
