var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppInputWrapper',{ref:"appInputWrapperRef",class:_vm.wrapperClass,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var input = ref.input;
return [_c('PrimeDropdown',_vm._g(_vm._b({ref:"primeDropdownRef",staticClass:"input-input",scopedSlots:_vm._u([{key:"value",fn:function(ref){
var selectedValue = ref.value;
var placeholder = ref.placeholder;
var option = ref.option;
return [(option)?_c('div',{staticClass:"selected-value"},[_vm._t("selected",function(){return [_c('span',[_vm._v(" "+_vm._s(option[_vm.selectedDisplayField || _vm.selectedValueField]))])]},{"value":selectedValue,"option":option})],2):_c('span',{staticClass:"select-placeholder"},[_vm._v(" "+_vm._s(placeholder)+" ")])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('AppRipple',[_c('div',{staticClass:"dropdown-option",attrs:{"analytics-name":_vm.analyticsName ? _vm.analyticsName + '-option-' + option[_vm.selectedValueField] : ''}},[_vm._t("option",function(){return [_c('span',[_vm._v(" "+_vm._s(option[_vm.selectedDisplayField || _vm.selectedValueField]))])]},{"option":option})],2),(option.separator)?_c('hr',{staticClass:"option-separator"}):_vm._e()])]}}],null,true),model:{value:(input.model),callback:function ($$v) {_vm.$set(input, "model", $$v)},expression:"input.model"}},'PrimeDropdown',_vm.primeDropdownBindings,false),_vm.primeDropdownListeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }