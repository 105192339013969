<template>
  <div class="app-input-dropdown-option-app-flags">
    <span v-if="!textAfterIcon" class="country-name-before-icon">
      {{ optionText }}
    </span>
    <AppFlag class="country-flag">
      <KeepAlive>
        <Component :is="icon"></Component>
      </KeepAlive>
    </AppFlag>
    <span v-if="textAfterIcon" class="country-name-after-icon">
      {{ optionText }}
    </span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppFlag from '@/components/AppFlag/AppFlag'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'

export default {
  name: 'AppInputDropdownOptionAppFlag',
  components: {
    AppFlag,
  },
  props: {
    flagName: {
      type: String,
      default: '',
      required: true,
    },
    optionText: {
      type: String,
      default: '',
    },
    textAfterIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup($props) {
    const icon = computed(() => {
      let flagName = $props.flagName
      if (flagName === 'FlagKV') {
        flagName = 'FlagCV'
      }
      return EuronetFlags[flagName] || null
    })
    return {
      icon,
    }
  },
}
</script>

<style scoped>
.app-input-dropdown-option-app-flags {
  @apply flex flex-row items-center;

  .country-name-after-icon {
    @apply ml-2;
  }

  .country-name-before-icon {
    @apply mr-2;
    @apply font-bold;
  }
}
</style>
