<template>
  <AppCard>
    <template #header>
      <AppCardHeader>
        <template v-if="mq.current !== 'xs'" #left>
          <AppButton theme="icon" @click="goBack">
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>
        <h3>Personal details</h3>
      </AppCardHeader>
    </template>

    <form @submit.prevent="submit">
      <!-- Dynamic Form -->
      <AppDynamicForm :fields="formFields" :disabled="isLoading" />
      <AppInputPhone
        v-if="phoneField"
        ref="appInputPhoneRef"
        v-model="form.phone"
        data-private
        :label="displayname"
        :placeholder="phonePlaceholder"
        :options="countries"
        :validation="phoneValidations.phone"
        :server-error="phoneServerError"
        :disabled="isLoading"
        @areaCodeInput="onAreaCodeInput"
        @areaCodeInputOption="onAreaCodeInputOption"
        @inputOnBlur="phoneFieldEvent"
      ></AppInputPhone>

      <!--Submit-->
      <AppCardFooter>
        <AppButton type="submit" :disabled="btnSubmitDisabled" :loading="isLoading">
          Continue
        </AppButton>
      </AppCardFooter>
    </form>
  </AppCard>
</template>

<script>
import { camelCase } from 'lodash-es'
import { computed, reactive, ref, toRef, watch } from '@vue/composition-api'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { cleanTrailingSpace } from '@/composables/useInputHelpers'
import { useRegistrationForm } from '@/forms/CorporateRegistrationForm'
import { useValidation } from 'vue-composable'
import { required } from '@vuelidate/validators'

import AppButton from '@/components/AppButton/AppButton'
import AppCard from '@/components/AppCard/AppCard'
import AppCardHeader from '@/components/AppCardHeader/AppCardHeader'
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm'
import { IconChevronLeft } from '@moneytransfer.ui/euronet-icons'
import AppInputPhone from '@/components/AppInputPhone/AppInputPhone'

import { useAnalyticsStore } from '@/stores/analytics'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'

export default {
  name: 'RegisterPersonal',
  components: {
    AppCardFooter,
    AppCard,
    AppCardHeader,
    AppButton,
    AppIcon,
    AppDynamicForm,
    IconChevronLeft,
    AppInputPhone,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()

    corporateRegistrationStore.activeStepIdx = 3
    corporateRegistrationStore.setActiveStepPageTitle('Personal details')
    const countries = computed(() => {
      return corporateRegistrationStore.offeredCountriesDialingCodesOptions
    })
    const { fields } = corporateRegistrationStore.getNewCustomerFields('contact')
    let copyFields = [...fields]

    const phoneDetails = corporateRegistrationStore.getPhoneDetails

    const isLiteRegistration = computed(() => authStore.isLiteRegistration)

    const form = reactive({
      phone: phoneDetails.numberOnly,
      areaCode: phoneDetails.areaCode,
    })

    const phoneServerError = ref([])

    const validDateOfBirth = ref(null)

    // Form functionality
    const {
      setup: setupFields,
      isLoading,
      formFields,
      hasFormFields,
      isFormInvalid,
    } = useRegistrationForm(store)

    const liteRegFieldsToIgnore = ['dateofbirth']

    // Setup details fields
    const setupDetailFields = (fields) => {
      if (fields) {
        if (isLiteRegistration.value) {
          fields = fields.filter(({ id }) => !liteRegFieldsToIgnore.includes(id))
        }
        copyFields = fields
      }
    }

    // Render only when fields exist
    watch(
      copyFields,
      () => {
        if (copyFields) {
          setupDetailFields(copyFields)
        }
      },
      { immediate: true }
    )

    const phoneField = copyFields.find(({ id }) => id === 'phonenumber')
    const phonePrefixField = copyFields.find(({ id }) => id === 'phoneprefix')
    const { displayname, validations } = phoneField

    // handle phone out of AppDynamicForm
    let phonePlaceholder = 'Enter phone number'
    if (phoneField) {
      const { placeholder } = phoneField
      phonePlaceholder = placeholder
      const index = copyFields.indexOf(phoneField)
      copyFields.splice(index, 1)
    }
    // do not need phonePrefix here
    if (phonePrefixField) {
      const index = copyFields.indexOf(phonePrefixField)
      copyFields.splice(index, 1)
    }

    if (copyFields) {
      setupFields(copyFields, 'contact')
    }

    const createValidation = () => {
      // Create validation object
      const validation = {
        $value: toRef(form, 'phone'),
      }
      for (const validator of validations) {
        const type = camelCase(validator.type)
        const value = validator.value

        let $validator, $message

        if (type === 'required') {
          $validator = required.$validator
          $message = `Please enter phone number`
        } else if (type === 'regex') {
          const validationRegex = new RegExp(value)
          $validator = (v) => {
            return validationRegex.test(v.replace(form.areaCode, ''))
          }
          $message = `Please enter a valid phone number`
        }
        // Add validator to validation
        if ($validator) {
          validation[type] = {
            $validator,
            $message: validator.error || $message,
          }
        }
      }

      return validation
    }

    const phoneValidations = useValidation({
      phone: createValidation(),
    })

    const phoneNumber = computed(() => {
      return form.phone.replace(form.areaCode, `${form.areaCode}-`)
    })

    const onAreaCodeInput = (v) => {
      form.areaCode = v
    }

    const onAreaCodeInputOption = (v) => {
      corporateRegistrationStore.phoneDetails = {
        number: phoneNumber.value,
        areaCode: v.value,
        nameAbbr: v.id,
      }
    }

    const btnSubmitDisabled = computed(() => {
      // TODO: Handle this as computed from store, the current form is broken and allowing values
      return (
        !hasFormFields.value ||
        isFormInvalid.value ||
        phoneValidations.$anyInvalid ||
        validDateOfBirth.value
      )
    })

    const getFieldValue = (id) => {
      const field = formFields.value.find((field) => field.id === id)
      if (field && field.value) {
        return field.value
      }
      return ''
    }

    // Delete leading & trailing spaces on input text after onblur event
    formFields.value.forEach((field, i) => {
      if (field.component.name === 'default' || field.component.name === '_default') {
        formFields.value[i]['listeners'] = {
          blur: (evt) => {
            if (field.value === evt.target.value) {
              field.value = cleanTrailingSpace(field.value)
            }
          },
        }
      }
      if (field.component.name === 'date') {
        formFields.value[i]['listeners'] = {
          validation: (componentValidation) => {
            validDateOfBirth.value = componentValidation['$anyInvalid']
          },
        }
      }
    })

    // Delete leading & trailing spaces on phone input
    const phoneFieldEvent = (evt) => {
      form.phone = form.areaCode + cleanTrailingSpace(evt.target.value)
    }

    const submit = async () => {
      const companyTypeName = corporateRegistrationStore.companyTypeName.toLocaleLowerCase()
      isLoading.value = true
      try {
        const phoneNumberArray = phoneNumber.value.split('-')
        if (phoneNumberArray.length < 2) {
          phoneNumberArray.push('')
        }
        const extraFields = {
          phoneprefix: `+${phoneNumberArray[0]}`,
          phonenumber: phoneNumberArray[1],
        }
        const errors = await corporateRegistrationStore.submitFieldData({
          group: 'contact',
          formFields,
          extraFields,
        })
        if (errors && errors.length > 0) {
          corporateRegistrationStore.showServerErrors({ errors, formFields })
        } else {
          analyticsStore.identify({
            userId: authStore.profileId,
            traits: {
              yearOfBirth: formFields.value.find((input) => input.id === 'dateofbirth')
                ? formFields.value.find((input) => input.id === 'dateofbirth').value
                : null,
              firstName: getFieldValue('firstname'),
              lastName: getFieldValue('lastname'),
              phoneCountry: phoneNumberArray[0],
              phoneNumber: phoneNumberArray[1],
            },
          })

          analyticsStore.track({
            event: 'Personal Details Completed',
            traits: {
              referenceSource: 'XEMT Business',
              email: authStore.lastLogin,
            },
          })
          corporateRegistrationStore.phoneNumber = phoneNumber.value
          if (isLiteRegistration.value) {
            router.push({ name: 'PersonalTerms' })
          } else if (
            // Go to personal selection
            companyTypeName === 'sole proprietorship' ||
            companyTypeName === 'sole trader' ||
            companyTypeName === 'other'
          ) {
            router.push({ name: 'RegisterPersonalAddressSelection' })
          } else {
            // Go to personal address search
            router.push({ name: 'RegisterPersonalAddressSearch' })
          }
        }
      } catch (ex) {
        appStore.logException({
          text: 'Exception during submiting personal details',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      isLoading.value = false
    }

    const goBack = () => {
      if (isLiteRegistration.value) {
        router.replace({ name: 'RegisterBusinessDetails' })
      } else {
        router.replace({ name: 'RegisterCurrencyAndVolume' })
      }
    }

    return {
      fields,
      btnSubmitDisabled,
      submit,
      formFields,
      phoneField,
      displayname,
      phonePlaceholder,
      phoneValidations,
      phoneServerError,
      phoneFieldEvent,
      countries,
      form,
      onAreaCodeInput,
      onAreaCodeInputOption,
      goBack,
      validDateOfBirth,
      isLoading: computed(() => isLoading.value),
      mq: reactive(useMediaQuery()),
    }
  },
}
</script>

<style lang="postcss" scoped>
.input + .card-footer {
  @screen sm {
    @apply pt-2;
  }
}
</style>
