<template>
  <AppInputDropdown
    v-model="model"
    class="input-phone-dropdown"
    :options="options"
    v-bind="[$attrs, $props]"
    selected-display-field="text"
    selected-value-field=""
    :filter="true"
    v-on="$listeners"
  >
    <template #selected="{ option }">
      <template v-if="$slots.selectOptionWithFlag">
        <AppInputDropdownOptionAppFlag
          :flag-name="`Flag${option.nameAbbr}`"
          :option-text="`+${option.value}`"
        />
      </template>
      <template v-else>+{{ option.value }}</template>
    </template>

    <template #option="{ option }">
      <slot name="option" :option="option">
        <!-- Default slot content -->
        <div class="flex w-full type-caption-bold">
          <AppInputDropdownOptionAppFlag :flag-name="`Flag${option.nameAbbr}`" />
          <span class="pr-2">+{{ option.value }}</span>
          <span class="pr-2 mr-28 text-gray">{{ option.country }}</span>
        </div>
      </slot>
    </template>
  </AppInputDropdown>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppInputDropdown from '@/components/AppInputDropdown/AppInputDropdown'
import AppInputDropdownOptionAppFlag from '@/components/AppInputDropdownOptionAppFlag/AppInputDropdownOptionAppFlag'

export default {
  name: 'AppInputPhoneDropdown',
  emits: ['input', 'selectedOption'],
  components: {
    AppInputDropdown,
    AppInputDropdownOptionAppFlag,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    validation: {
      type: Object,
      default: () => {},
    },
    helperText: {
      type: String,
      default: '',
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    })

    return {
      model,
    }
  },
}
</script>

<style scoped></style>
