<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :aria-labelledby="titleId"
      viewBox="0 0 28 20"
      role="presentation"
      fill="none"
    >
      <title :id="titleId" lang="en">{{ name }}</title>
      <g>
        <slot />
      </g>
    </svg>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useComponentId } from '@/composables/useComponent'
export default {
  name: 'AppFlag',
  props: {
    name: {
      type: String,
      default: 'Icon',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
  },
  setup(props) {
    const titleId = useComponentId('title')

    // Computed Height
    const aspectRatio = 5 / 7
    const height = computed(() => {
      return props.width * aspectRatio
    })

    return {
      height,
      titleId,
    }
  },
}
</script>

<style scoped>
div {
  @apply inline-flex;
  @apply relative;
  @apply rounded-sm;
  &::after {
    content: ' ';
    @apply border-gray-light rounded-sm box-border border;
    @apply absolute top-0 right-0 bottom-0 left-0;
  }
}
svg {
  @apply inline-flex;
  @apply align-baseline;
  @apply rounded-sm;
}
</style>
